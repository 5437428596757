<template lang="pug">
.dashboard
	.dashboard-sidebars(sticky="true").d-none.d-sm-block.d-md-block
		SidebarUser
	.dashboard-content
		b-container.mb-5.pb-5
			b-row
				b-col.pt-4
					#profile
						b-button(variant="okcarpremium", @click="closeSession").float-right
							font-awesome-icon(icon="sign-out-alt")
							|  Cerrar Session
						h2 Mi Perfil
						p.text-muted Aquí podrás ver y editar los datos de tu perfil
						b-card(sub-title="Imágenes de perfil")
							b-card-text.mt-4
								b-row.mb-md-4
									b-col(cols="12", md="3").text-md-right
										label Foto principal
									b-col(cols="12", md="8", lg="6").offset-md-1.d-flex
										b-avatar(v-if="!user.avatar" :text="getName", size="5rem")
										b-avatar(v-else :src="getImage", size="5rem" :title="getName")
										.upload-button.ml-3
											label.btn.btn-outline-secondary.rounded-pill
												input(
													@change="handleImage"
													type="file"
													ref="file"
													name="file"
													accept="image/*"
												).d-none
												span(v-text="buttonTextAvatar")
											div
												p.text-muted Aceptamos formatos .jpg y mínimo 400 x 400px
						b-card(sub-title="Datos basicos").mt-2
							b-card-body
								validation-observer(ref="observer", v-slot="{ invalid }")
									b-form(@submit.prevent="updateProfile")
										b-row.mb-3
											b-col(cols="12", md="3").text-md-right
												label Nombre y apellidos:
											b-col(cols="12", md="6").container-input.offset-md-1.d-flex
												validation-provider(
													name="Nombre y apellidos",
													v-slot="validationContext"
													rules="required"
												)
													b-form-group
														b-form-input(
															v-model="formUser.name"
															type="text"
															required
															autocomplete="name"
															:state="getValidationState(validationContext)"
															aria-describedby="input-name"
														)
														b-form-invalid-feedback#input-name {{ validationContext.errors[0] }}
										b-row.mb-3
											b-col(cols="12", md="3").text-md-right
												label Teléfono:
											b-col(cols="12", md="6").container-input.offset-md-1.d-flex
												validation-provider(
													name="Teléfono",
													v-slot="validationContext"
													rules="required|integer|min:9"
												)
													b-form-group
														b-form-input(
															v-model="formUser.phone"
															type="tel"
															required
															autocomplete="phone"
															:state="getValidationState(validationContext)"
															aria-describedby="input-phone"
														)
														b-form-invalid-feedback#input-phone {{ validationContext.errors[0] }}
										b-row.mb-3
											b-col(cols="12", md="3").text-md-right
												label Dirección de email:
											b-col(cols="12", md="6").container-input.offset-md-1.d-flex
												validation-provider(
													name="E-mail"
													rules="required|email"
													v-slot="validationContext"
												)
													b-form-input(
														v-model="formUser.email"
														type="email"
														required
														autocomplete="email"
														:state="getValidationState(validationContext)"
														aria-describedby="input-email"
													)
													b-form-invalid-feedback#input-email {{ validationContext.errors[0] }}
										b-row
											b-col(cols="12" md="6").offset-md-4.d-flex
												b-button(
													type="submit"
													variant="outline-okcarpremium"
													block
													:disabled="invalid"
													v-text="buttonTextUpdate"
												).mb-2
						UpdatePassword
	FooterNavbarMobile
</template>
<script>
import SidebarUser from "./SidebarUser";
import { mapState, mapActions } from "vuex";
import UpdatePassword from "./UpdatePassword";
import FooterNavbarMobile from "@/components/FooterNavbarMobile.vue";

export default {
  name: "Profile",
  components: {
    SidebarUser,
    UpdatePassword,
    FooterNavbarMobile,
  },
  data() {
    return {
      user: {
        id: 0,
        active: false,
        avatar: null,
        email: null,
        name: null,
        phone: 0,
        role: 0,
      },
      formUser: {
        name: "",
        phone: "",
        email: "",
      },
      image: "",
      buttonTextAvatar: "Cambiar foto",
      buttonTextUpdate: "Actualizar datos",
      buttonTextPassword: "Actualizar contraseña",
      buttonDisabled: false,
    };
  },
  created() {
    this.user = this.credentials;
  },
  mounted() {
    this.setForm();
  },
  computed: {
    ...mapState("auth", ["credentials"]),
    getName() {
      if (this.user.name) {
        const userName = this.user.name.replace(/\./g, "").trim();
        let fullname = userName.split(" ");
        if (fullname[1]) return fullname[0].charAt(0) + fullname[1].charAt(0);
        else return userName.substring(0, 2);
      } else {
        return "";
      }
    },
    getImage() {
      if (this.user.avatar === undefined && this.user.avatar.length > 30) return "";
      else return `${this.$axios.defaults.baseURL}/images/profile/${this.user.avatar}`;
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    handleImage(e) {
      this.buttonTextAvatar = "...Cargando imagen";
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.user.avatar = e.target.result;
        this.uploadImage();
      };
      reader.readAsDataURL(fileObject);
    },
    uploadImage() {
      this.$axios
        .put(`/api/v1/auth/update/avatar`, { image: this.user.avatar })
        .then((resp) => {
          const user = resp.data.user;
          this.user.avatar = user.avatar;
          localStorage.setItem(
            "vuex",
            JSON.stringify({
              credentials: {
                active: true,
                id: user.id,
                avatar: user.avatar,
                name: user.name,
                email: user.email,
                phone: user.phone,
              },
            })
          );
          this.buttonTextAvatar = "Cambiar foto";
        })
        .catch((err) => {
          return new Error(err);
        });
    },
    updateProfile() {
      this.buttonTextUpdate = "Cargando ...";
      this.$axios
        .put(`/api/v1/auth/update`, this.formUser)
        .then((resp) => {
          const user = resp.data.user;
          localStorage.setItem(
            "vuex",
            JSON.stringify({
              credentials: {
                active: true,
                id: user.id,
                avatar: user.avatar,
                name: user.name,
                email: user.email,
                phone: user.phone,
              },
            })
          );
          this.setUpdated(user);
          this.$swal({
            title: "Actualización de perfil!",
            text: "Perfil actualizado con éxito",
            icon: "success",
            confirmButtonClass: "btn btn-outline-primary",
            confirmButtonText: "Ok",
            buttonsStyling: false,
          });
          this.buttonTextUpdate = "Actualizar datos";
        })
        .catch((error) => {
          this.buttonTextUpdate = "Actualizar datos";
          this.$swal({
            title: "Actualización de perfil!",
            text: "Error al realizar la actualización del perfil.",
            icon: "error",
            confirmButtonClass: "btn btn-outline-danger",
            confirmButtonText: "Ok",
            buttonsStyling: false,
          });
          console.error(error);
        });
    },
    setForm() {
      this.formUser.name = this.user.name;
      this.formUser.phone = this.user.phone;
      this.formUser.email = this.user.email;
    },
    setUpdated(user) {
      this.user.name = user.name;
      this.user.phone = user.phone;
      this.user.email = user.email;
    },
    closeSession() {
      this.logout()
        .then(() => {
          if (this.$route.path !== "/") this.$router.push("/");
          this.$bvToast.toast("Cierre de sesión", {
            title: "Sessión cerrada con éxito",
            variant: "info",
            autoHideDelay: 3000,
            toaster: "b-toaster-bottom-right",
            solid: true,
          });
        })
        .catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss" scoped>
.container-input {
  & > span {
    width: 100%;
  }
}
</style>
