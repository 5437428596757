<template lang="pug">
b-card(sub-title="Cambio de contraseña").mt-2
	b-card-body
		validation-observer(ref="observer", v-slot="{ invalid }")
			b-form(ref="formUpdatePassword", @submit.prevent="updatePassword")#formUpdatePassword
				b-row.mb-3
					b-col(cols="12", md="3").text-md-right
						label Contraseña Actual:
					b-col(cols="12", md="6").container-input.offset-md-1.d-flex
						validation-provider(
							name="contraseña actual",
							v-slot="validationContext"
							rules="required"
						)
							b-form-group
								b-form-input(
									v-model="currentPassword",
									type="password",
									name="currentPassword",
									autocomplete="off",
									required,
									:state="getValidationState(validationContext)",
									aria-describedby="currentPassword"
								)
								b-form-invalid-feedback#currentPassword(v-text="validationContext.errors[0]")
				b-row.mb-3
					b-col(cols="12", md="3").text-md-right
						label Nueva Contraseña:
					b-col(cols="12", md="6").container-input.offset-md-1.d-flex
						validation-provider(
							name="nueva contraseña",
							v-slot="validationContext"
							vid="newPassword"
							rules="required|min:6"
						)
							b-form-group
								b-form-input(
									v-model="newPassword",
									name="newPassword",
									type="password",
									autocomplete="off",
									required,
									:state="getValidationState(validationContext)",
									aria-describedby="newPassword"
									:ref="newPassword"
								)
								b-form-invalid-feedback#newPassword {{ validationContext.errors[0] }}
				b-row.mb-3
					b-col(cols="12", md="3").text-md-right
						label Confirmar nueva contrseña:
					b-col(cols="12", md="6").container-input.offset-md-1.d-flex
						validation-provider(
							name="confirmar nueva contrseña",
							v-slot="validationContext"
							rules="required|min:6|confirmed:newPassword"
						)
							b-form-group
								b-form-input(
									v-model="confirmPassword",
									name="confirmPassword",
									type="password",
									autocomplete="off",
									required,
									:state="getValidationState(validationContext)",
									aria-describedby="confirmPassword",
								)
								b-form-invalid-feedback#confirmPassword(v-text="validationContext.errors[0]")
				b-row
					b-col(cols="12", md="6").offset-md-4.d-flex
						b-button(type="submit", variant="outline-okcarpremium", block, :disabled="invalid", v-text="buttonTextPassword").mb-2
</template>
<script>
export default {
  name: "UpdatePassword",
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      buttonTextPassword: "Actualizar contraseña",
    };
  },
  methods: {
    updatePassword(event) {
      this.buttonTextPassword = "Cargando ...";
      const formData = {
        password_current: this.currentPassword,
        password: this.newPassword,
        password_confirmation: this.confirmPassword,
      };
      this.$axios
        .put(`/api/v1/auth/update/password`, formData)
        .then((resp) => {
          const data = resp.data;
          this.$swal({
            title: "Actualización de contraseña!",
            text: data.message,
            icon: "success",
            confirmButtonClass: "btn btn-outline-okcarpremium",
            confirmButtonText: "Ok",
            buttonsStyling: false,
          });
          this.buttonTextPassword = "Actualizar contraseña";
          this.currentPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
        })
        .catch((error) => {
          this.buttonTextPassword = "Actualizar contraseña";
          if (error.hasOwnProperty("response")) {
            const result = error.response;
            this.$swal({
              title: "Actualización de contraseña!",
              text: result.data.message,
              icon: "error",
              confirmButtonClass: "btn btn-outline-danger",
              confirmButtonText: "Ok",
              buttonsStyling: false,
            });
          }
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
<style lang="scss">
.container-input {
  & > span {
    width: 100%;
  }
}
</style>
